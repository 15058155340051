exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contacts-index-tsx": () => import("./../../../src/pages/contacts/index.tsx" /* webpackChunkName: "component---src-pages-contacts-index-tsx" */),
  "component---src-pages-delivery-index-tsx": () => import("./../../../src/pages/delivery/index.tsx" /* webpackChunkName: "component---src-pages-delivery-index-tsx" */),
  "component---src-pages-discounts-index-tsx": () => import("./../../../src/pages/discounts/index.tsx" /* webpackChunkName: "component---src-pages-discounts-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-index-tsx": () => import("./../../../src/pages/jobs/index.tsx" /* webpackChunkName: "component---src-pages-jobs-index-tsx" */),
  "component---src-pages-large-format-index-tsx": () => import("./../../../src/pages/large-format/index.tsx" /* webpackChunkName: "component---src-pages-large-format-index-tsx" */),
  "component---src-pages-offset-index-tsx": () => import("./../../../src/pages/offset/index.tsx" /* webpackChunkName: "component---src-pages-offset-index-tsx" */),
  "component---src-pages-printing-index-tsx": () => import("./../../../src/pages/printing/index.tsx" /* webpackChunkName: "component---src-pages-printing-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-souvenir-index-tsx": () => import("./../../../src/pages/souvenir/index.tsx" /* webpackChunkName: "component---src-pages-souvenir-index-tsx" */)
}

